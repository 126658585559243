<template>
  <v-container fluid>
    <v-row class="hidden-sm-and-down">
      <v-list color="#FFFFFF">
        <v-list-item-group>
          <h6>
            Admin Dashboard
          </h6>
          <v-list-item
            v-for="item in menuitems"
            :key="item.id"
            :to="item.path"
            active-class="highlighted"
            :class="item.path === $route.path ? 'highlighted' : ''"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <h6 class="mt-2">
            Support
          </h6>
          <v-list-item
            v-for="item in settingMenuitems"
            :key="item.id"
            :to="item.path"
            active-class="highlighted"
            :class="item.path === $route.path ? 'highlighted' : ''"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-row>

    <v-row class="hidden-md-and-up">
      <v-slide-group center-active show-arrows>
        <v-slide-item
          v-for="item in menuitems"
          :key="item.id"
          active-class="highlighted"
          center-active
          :class="item.path === $route.path ? 'highlighted' : ''"
        >
          <v-btn class="mx-2" depressed rounded :to="item.path">
            {{ item.text }}
          </v-btn>
        </v-slide-item>
      </v-slide-group>
    </v-row>

    <v-row class="mt-5 hidden-sm-and-down">
      <v-col cols="12" align="left">
        <p><b>©  qikPharma. 2022</b></p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AdminMenu",

  components: {},

  data: () => ({
    menuitems: [
      {
        id:1,
        text: "Overview",
        path: "/admin/dashboard/0/en",
        icon: "mdi-view-dashboard-outline",
      },
      {
        id:2,
        text: "Orders",
        path: "/admin/all-orders",
        icon: "mdi-order-bool-ascending-variant",
      },
      {
        id:3,
        text: "Categories",
        path: "/admin/all-categories",
        icon: "mdi-email-multiple-outline",
      },
      {
        id:4,
        text: "Products",
        path: "/admin/all-products",
        icon: "mdi-square-opacity",
      },
      {
        id:5,
        text: "User Accounts",
        path: "/admin/users-account/module",
        icon: "mdi-account-group",
      },
      {
        id:6,
        text: "User Statistics",
        path: "/admin/user-statistics",
        icon: "mdi-list-box-outline",
      },
      {
        id:7,
        text: "Professionals",
        path: "/admin/0/professionals",
        icon: "mdi-account-tie-hat",
      },
      { id:8, text: "App Downlaod", path: "#", icon: "mdi-apple-icloud" },
      {
        id:9,
        text: "Manage Logistic",
        path: "/admin/manage-logistics/module",
        icon: "mdi-truck-fast-outline",
      },
      {
        id:10,
        text: "Wallet",
        path: "/admin/wallets",
        icon: "mdi-square-opacity",
      },
    ],
    settingMenuitems: [
      { id:10, text: "Settings", path: "#", icon: "mdi-cog-outline" },
    ],
  }),
};
</script>

<style scoped>
.scanBar {
  background: #f4faff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.barcodeTitle {
  color: #000000;
  font-size: 16px;
  text-align: center;
}

.barcodeText {
  color: #1882ff;
  font-size: 14px;
  margin-top: 15px;
  text-align: center;
}

.centerImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
}

.highlighted {
  background: linear-gradient(
    90deg,
    rgba(114, 189, 97, 0.1) 0%,
    rgba(114, 189, 97, 0) 117.12%
  ) !important;

  border-left: 3px solid #72bd61;
  /* border-left: 3px solid #7cbf46; */
}

.v-list-item
{
  padding: 0 30px !important;
}
</style>
